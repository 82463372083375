import { Fragment } from 'react';
import About from '../components/homePage/About'
import Banner from '../components/homePage/Banner'
import Bookshelf  from '../components/homePage/Bookshelf'
import Services from '../components/homePage/Services'
import Testimonials from '../components/homePage/Testimonials'

export default function Home() {
  return (
    <Fragment>
      <Banner />
      <Bookshelf id="portfolio" />
      <About id="about" />
      <Services id="services" />
      <Testimonials id="testimonials" />
    </Fragment>
  );
}
