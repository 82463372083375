import React, { useEffect } from 'react';
import SafeComponent from '../components/security/SafeComponent';
import img01 from '../assets/img/policy.png'; 

const handleScrollToSection = (sectionId) => {
  const section = document.querySelector(`#${sectionId}`);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const PrivacyPolicy = () => {

  useEffect(() => {
    const sectionId = window.location.hash.slice(1);
    if (sectionId) {
      handleScrollToSection(sectionId);
    }
  }, []);

  const complaintsContent = `
  <h5 className="fw-bold">10. Handling Customer Complaints and Suggestions</h5>
  <p align="justify">
    You may direct any questions or inquiries with respect to our privacy policy or our practices by contacting
    <span style="color: rgb(54, 149, 144);"> 1-888-415-4882</span> by phone or by email at
    <span style="color: rgb(54, 149, 144);"> info@thenewleafmedia.com</span>.
  </p>
`;

  return (
    <>
      <section id="hero2" className="d-flex flex-column justify-content-end align-items-center">
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff"></use>
          </g>
        </svg>
      </section>

      <main id="privacy-policy">
        <section id="privacy-policy" className="policy">
          <div className="container">
            <div className="section-title mb-3">
              <h2>NewLeaf</h2>
              <p>Privacy Policy</p>
            </div>
            <div className="row content">
              <div className="col-lg-6">
                <p align="justify">
                  New Leaf Media is committed to maintaining the accuracy, confidentiality, and security of your
                  personally identifiable information ("Personal Information"). As part of this commitment, our privacy
                  policy governs our actions as they relate to the collection, use, and disclosure of Personal Information.
                  Our privacy policy is based upon the values set by the United States Privacy Law and is governed by,
                  construed, and enforced in accordance with the laws of the State of Ohio and the United States of America
                  applicable to Ohio-based businesses and websites. Your use of this Website constitutes your consent to
                  their jurisdiction.
                </p>

                <h5 className="fw-bold">1. Introduction</h5>
                <p align="justify">
                  We are responsible for maintaining and protecting the Personal Information under our control. We have
                  designated an individual or individuals who is/are responsible for compliance with our privacy policy.
                </p>

                <h5 className="fw-bold">2. Identifying Purposes</h5>
                <p align="justify">
                  We collect, use, and disclose Personal Information to provide you with the product or service you have
                  requested and to offer you additional products and services we believe you might be interested in. The
                  purposes for which we collect Personal Information will be identified before or at the time we collect the
                  information. In certain circumstances, the purposes for which information is collected may be clear, and
                  consent may be implied, such as where your name, address, and payment information are provided as part of
                  the order process.
                </p>

                <h5 className="fw-bold">3. Consent</h5>
                <p align="justify">
                  Knowledge and consent are required for the collection, use, or disclosure of Personal Information except
                  where required or permitted by law. Providing us with your Personal Information is always your choice.
                  However, your decision not to provide certain information may limit our ability to provide you with our
                  products or services. We will not require you to consent to the collection, use, or disclosure of
                  information as a condition to the supply of a product or service, except as required to be able to supply
                  the product or service.
                </p>

                <h5 className="fw-bold">4. Limiting Collection</h5>
                <p align="justify">
                  The Personal Information collected will be limited to those details necessary for the purposes identified
                  by us. With your consent, we may collect Personal Information from you in person, over the telephone, or
                  by corresponding with you via mail, facsimile, or the Internet.
                </p>

                <h5 className="fw-bold">5. Limiting Use, Disclosure, and Retention</h5>
                <p align="justify">
                  Personal Information may only be used or disclosed for the purpose for which it was collected unless you
                  have otherwise consented, or when it is required or permitted by law. Personal Information will only be
                  retained for the period of time required to fulfill the purpose for which we collected it or as may be
                  required by law.
                </p>

                <h5 className="fw-bold">6. Accuracy</h5>
                <p align="justify">
                  Personal Information will be maintained in as accurate, complete, and up-to-date form as is necessary to
                  fulfill the purposes for which it is to be used.
                </p>
              </div>

              <div className="col-lg-6 pt-4 pt-lg-0">
                <h5 className="fw-bold">7. Safeguarding Customer Information</h5>
                <p align="justify">
                  Personal Information will be protected by security safeguards that are appropriate to the sensitivity level
                  of the information. We take all reasonable precautions to protect your Personal Information from any loss
                  or unauthorized use, access, or disclosure.
                </p>

                <h5 className="fw-bold">8. Openness</h5>
                <p align="justify">
                  We will make information available to you about our policies and practices with respect to the management
                  of your Personal Information.
                </p>

                <h5 className="fw-bold">9. Customer Access</h5>
                <p align="justify">
                  Upon request, you will be informed of the existence, use, and disclosure of your Personal Information and
                  will be given access to it. You may verify the accuracy and completeness of your Personal Information and
                  may request that it be amended if appropriate. However, in certain circumstances permitted by law, we
                  will not disclose certain information to you. For example, we may not disclose information relating to you
                  if other individuals are referenced or if there are legal, security, or commercial proprietary restrictions.
                </p>
                
                <p>
                  <SafeComponent userInput={complaintsContent} />
                </p>

                <h5 className="fw-bold">Additional Information:</h5>
                <h6 className="fw-bold">Cookies</h6>
                <p align="justify">
                  A cookie is a small computer file or piece of information that may be stored in your computer's hard drive
                  when you visit our websites. We may use cookies to improve our website’s functionality and, in some cases,
                  to provide visitors with a customized online experience. Cookies are widely used and most web browsers are
                  configured initially to accept cookies automatically. You may change your Internet browser settings to
                  prevent your computer from accepting cookies or to notify you when you receive a cookie so that you may
                  decline its acceptance. Please note, however, if you disable cookies, you may not experience optimal
                  performance of our website.
                </p>

                <div className="d-flex justify-content-center align-items-center">
                  <img src={img01} alt="Privacy Policy" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PrivacyPolicy;
