import React from 'react';
import { Carousel } from 'react-bootstrap';
import useSwiper from '../../hooks/useSwiper';
import { getSwiperOptions } from '../../utils/swiperUtils';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.min.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/pagination';

import img01 from '../../assets/img/testimonials/barbara_pierce.jpg';
import img02 from '../../assets/img/testimonials/david_perry.jpg';
import img03 from '../../assets/img/testimonials/karyn_ward.jpg';
import img04 from '../../assets/img/testimonials/leslie_harrison.jpg';
import img05 from '../../assets/img/testimonials/digby_horlock.jpg';

const Testimonials = () => {
  useSwiper(".testimonials-slider", getSwiperOptions());

  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <div className="section-title">
          <h2>Testimonials</h2>
          <p>What they are saying about us</p>
        </div>

        {/* Video Testimonials Carousel */}
        <div className="swiper-slide">
          <div className="testimonial-item">
            <Carousel id="carouselExample">
              <Carousel.Item>
                <div className="container">
                  <h3>Natalie Olliever</h3>
                  <h4>Author of the Travel To Free The Soul</h4>
                  <iframe
                    className="video-fluid d-block w-100"
                    src="https://www.youtube-nocookie.com/embed/zWyeeVTY4wg"
                    allowFullScreen
                    title="Natalie Olliever Testimonial"
                  ></iframe>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="container">
                  <h3>Manuel Pelaez</h3>
                  <h4>Author of the Darpa Series</h4>
                  <iframe
                    className="video-fluid d-block w-100"
                    src="https://www.youtube-nocookie.com/embed/91s29-A7EbU"
                    allowFullScreen
                    title="Manuel Pelaez Testimonial"
                  ></iframe>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="container">
                  <h3>Creative Pitch: Hollywood Executive Session</h3>
                  <h4>Joined by Author Manuel Pelaez</h4>
                  <iframe
                    className="video-fluid d-block w-100"
                    src="https://www.youtube-nocookie.com/embed/srVboLNmaaQ"
                    allowFullScreen
                    title="Creative Pitch Testimonial"
                  ></iframe>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        {/* Text Testimonials */}
        <div className="swiper-slide">
          <div className="testimonial-item">
            <p>
              <i className="bx bxs-quote-alt-left quote-icon-left"></i>
              I am the author of fourteen books written especially for school
              age children. As a veteran writer who has used the services of many other self-publishing companies,
              I find New Leaf Media to be the most transparent. Unfortunately
              that was not the case in any of my previous experiences.
              <br /><br />
              One of company was actually on the brink of bankruptcy when I
              started with them. And sadly, my three books
              were never completed. Another, corrupted my files when I asked
              to terminate contract when the process went many months pass the
              schedule date of completion as per their contract.
              <br /><br />
              I am certainly grateful that New Leaf Media was recommended when
              I needed the help of a reputable company
              to help reclaim my books. I had wasted too much time and money
              trusting those that made fabulous promises, but never delivered or
              honored in their contracts! They are the only company who has provided me detailed reports of what I have
              earned and I finally got my first check within a short span of time working
              with them! Fortunately, now many of my books have been republished and the integrity of my work has
              been restored.
              <br /><br />
              Thank you New Leaf Media! We haven't finished yet, there's more
              to come!
              <i className="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
            <img src={img01} className="testimonial-img" alt="Barbara Pierce" />
            <h3>Barbara Pierce</h3>
            <h4>Author</h4>
          </div>
        </div>

        {/* Swiper for Additional Testimonials */}
        <div className="testimonials-slider swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Have you noticed that THE HOLIDAY ADVENTURES OF BONZA AND SONJA is a 'Book of the Month' at Barnes and Nobles? 
                  Great work!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src={img02} className="testimonial-img" alt="David Perry" />
                <h3>David Perry</h3>
                <h4>Author</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  So happy with the service from The New Leaf Media. Adapted
                  to my needs and budget. Much better than my
                  previous publisher.
                  Totally recommend them.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src={img03} className="testimonial-img" alt="Karyn Ward" />
                <h3>Karyn Ward</h3>
                <h4>Author of 'Why Does Mummy Cry?'</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  I have received my royalty payment and I want to thank you very much!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src={img04} className="testimonial-img" alt="Leslie Harrison" />
                <h3>Leslie Harrison</h3>
                <h4>Author of 'The Closest of Friends'</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Firstly, thank you for your patience. All other publishing
                  companies I've been involved with have been
                  very impatient - high-powered sales persons pressing me to
                  do things in no time at all.
                  The New Leaf Media have been understanding, sensitive and
                  easy-going. It's a nice side of America I am
                  seeing.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src={img05} className="testimonial-img" alt="Digby Horlock" />
                <h3>Digby Horlock</h3>
                <h4>Author of Doombreaker</h4>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
