export const countUp = (element, endValue, duration = 2000) => {
    let startValue = 0;
    const stepTime = Math.abs(Math.floor(duration / endValue));
  
    const updateCounter = () => {
      startValue += 1;
      element.textContent = startValue;
      if (startValue < endValue) {
        setTimeout(updateCounter, stepTime);
      }
    };
  
    updateCounter();
  };
  