import React from 'react';
import { useLocation } from 'react-router-dom';
import useTxtRotate from '../../hooks/useTxtRotate';

const Banner = () => {
  const rotatingTexts = ["publishing.", "to film representation.", "marketing.", "branding.", "editing."];
  const period = 1000;
  const location = useLocation();
  const text = useTxtRotate(rotatingTexts, period);

  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      setTimeout(() => {
        window.location.replace(`/#${sectionId}`);
      }, 500);
    } else {
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <section id="hero" className="d-flex flex-column justify-content-end align-items-center">
      <div className="carousel-container">
        <div className="row mt-2">
          <h1>
            Book <span className="wrap">{text}</span>
          </h1>
        </div>
        <div className="row logo-font mt-4">
          <h5>We do it all. Let us help you with your book representation needs.</h5>
        </div>
          <span
            className="btn-get-started animate__animated animate__fadeInUp scrollto no-underline"
            onClick={() => handleScrollToSection('about')}
          >
            Read More
          </span>
      </div>
      <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"></use>
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="#fff"></use>
        </g>
      </svg>
    </section>
  );
};

export default Banner;
