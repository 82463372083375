import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { countUp } from '../../utils/counterUtils';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import img01 from '../../assets/img/martech.png';

const About = () => {
  const location = useLocation();

  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      // Navigate to the home page first, then scroll
      window.location.replace(`/#${sectionId}`);
    } else {
      // Scroll directly to the section
      const section = document.querySelector(`#${sectionId}`);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    const counters = document.querySelectorAll('.purecounter');
    
    counters.forEach(counter => {
      const endValue = parseInt(counter.textContent, 10);
      countUp(counter, endValue);
    });

  }, []);

  return (
    <div>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>About</h2>
            <p>Who we are</p>
          </div>
          <div className="row content">
            <div className="col-lg-6">
              <p align="justify">
                The publishing industry is constantly shifting as consumer demands increase and distribution channels
                continue to move. Our mission is always to be that guiding light for authors and help them navigate the
                necessary paths to publishing their works and generating revenues.
              </p>
              <p align="justify">
                We've done the heavy lifting of networking with some of the major publishing companies and helped cut
                through your project's red tape with them. When we see a vendible project, we're ready to push through and
                offer a holistic approach to your publishing needs. It's not just about getting your book out there for
                sale but also working on managing and representing you when necessary, which is something other types of
                publishing companies tend to forget.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p align="justify">
                We value marketing and embrace it as our core tool to work towards building your own personal fanbase for
                your novels and other works. This helps to develop a more active and engaged community curated
                specifically for you.
              </p>
              <span 
                className="btn btn-primary btn-learn-more" 
                onClick={() => handleScrollToSection('services')}
              >
                Learn More
              </span>
            </div>
          </div>
        </div>
      </section>
      <section id="counts" className="counts">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="count-box">
                <i className="bi bi-buildings" style={{ color: '#2aa4a5' }}></i>
                <div className="count-content">
                  <span className="purecounter">6</span>
                  <p>Years Experience</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="count-box">
                <i className="bi bi-journal-richtext" style={{ color: '#2aa4a5' }}></i>
                <div className="count-content">
                  <span className="purecounter">331</span>
                  <p>Completed Projects</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="count-box">
                <i className="bi bi-emoji-laughing" style={{ color: '#2aa4a5' }}></i>
                <div className="count-content">
                  <span className="purecounter">287</span>
                  <p>Satisfied Clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="awards mt-4">
        <div className="container">
          <div className="award-box text-center mt-3">
            <h3>Company Achievement</h3>
            <h4>Award</h4>
            <a href="https://www.innovationinbusiness.com/winners/new-leaf-media-llc/">
              <img src={img01} className="rounded" alt="martech" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
