import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import img01 from '../assets/img/contact/published.gif';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    optIn: false
  });

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Sanitize the form data
    const sanitizedData = {
      name: DOMPurify.sanitize(formData.name),
      email: DOMPurify.sanitize(formData.email),
      phone: DOMPurify.sanitize(formData.phone),
      optIn: formData.optIn
    };

    // Create a mailto link
    const mailtoLink = `mailto:info@thenewleafmedia.com?subject=Contact&body=${encodeURIComponent(
      `Name: ${sanitizedData.name}\nEmail: ${sanitizedData.email}\nPhone Number: ${sanitizedData.phone}`
    )}`;

    // Trigger the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <>
      <section id="hero2" className="d-flex flex-column justify-content-end align-items-center">
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff"></use>
          </g>
        </svg>
      </section>

      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Get in touch</p>
          </div>
          <div className="row gx-lg-0 gy-4">
            <div className="col-lg-4">
              <div className="info-container d-flex flex-column align-items-center justify-content-center">
                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <p className="mb-3">If you have questions or ideas we’d love to hear from you via email:</p>
                    <p>info@thenewleafmedia.com</p>
                    <button
                      className="mt-3 email-button"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#contact-us"
                    >
                      Send us an email
                    </button>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>Location:</h4>
                    <p>470 W Broad St #1276 Columbus, OH 43215</p>
                  </div>
                </div>

                <div className="info-item d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>Call:</h4>
                    <p>+1-888-415-4882</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form className="php-email-form" onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={img01} alt="Contact" className="img-fluid" />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="contact-us"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Send Us Email</h5>
                <button
                  type="button"
                  className="btn-close btn-close-black"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" align="justify">
                <form id="emailForm" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      style={{ color: 'black', backgroundColor: 'white' }}
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      style={{ color: 'black', backgroundColor: 'white' }}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone Number:</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      placeholder="+1 (XXX) XXX-XXXX"
                      pattern="\+1\s\(\d{3}\)\s\d{3}-\d{4}"
                      title="Please enter a valid US Number"
                      value={formData.phone}
                      onChange={handleChange}
                      style={{ color: 'black', backgroundColor: 'white' }}
                      required
                    />
                    <small className="form-text text-muted text-white">Format: +1 (XXX) XXX-XXXX</small>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="optIn"
                      checked={formData.optIn}
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="optIn">
                      By signing up, you agree with Upworthy's{' '}
                      <a href="/privacy-policy">Privacy Policy</a>.
                    </label>
                  </div>
                  <button type="submit" className="btn btn-submit">Submit</button>
                </form>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
