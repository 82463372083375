import { useState, useEffect, useCallback } from 'react';
import { getTextToShow, getNewDelta } from '../utils/txtRotateUtils';

const useTxtRotate = (toRotate, period) => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [delta, setDelta] = useState(300 - Math.random() * 100);

  const deletingSpeed = 150; // Speed of deleting characters

  const handleTick = useCallback(() => {
    const i = loopNum % toRotate.length;
    const fullTxt = toRotate[i];

    setText(prevText => getTextToShow(prevText, fullTxt, isDeleting));

    if (!isDeleting && text === fullTxt) {
      setDelta(period); // Set delta to the period for display time
      setIsDeleting(true);
    } else if (isDeleting && text === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(200); // Time to wait before starting to type the next text
    } else {
      setDelta(getNewDelta(isDeleting, delta, period, deletingSpeed));
    }
  }, [isDeleting, text, loopNum, toRotate, delta, period]);

  useEffect(() => {
    const ticker = setTimeout(handleTick, delta);
    return () => clearTimeout(ticker);
  }, [text, delta, handleTick]);

  return text;
};

export default useTxtRotate;
