import { useEffect } from 'react';
import Swiper from 'swiper/bundle';

const useSwiper = (selector, options) => {
  useEffect(() => {
    const swiper = new Swiper(selector, options);

    return () => {
      swiper.destroy(true, true);
    };
  }, [selector, options]);
};

export default useSwiper;
