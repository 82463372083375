import { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AppNavBar from './components/AppNavBar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/Privacy'

import './assets/css/App.css';

function App() {
  return (
    <BrowserRouter>
      <Fragment>
        <AppNavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          {/* Add other routes here as needed */}
        </Switch>
        <Footer />
      </Fragment>
    </BrowserRouter>
  );
}

export default App;

