import React from 'react';
import { Modal } from 'react-bootstrap';
import useModalManager from '../../hooks/useModalManager';
import { getModalClassName, getButtonStyle } from '../../utils/modalUtils';

import img01 from '../../assets/img/services/book-to-film.png';
import img02 from '../../assets/img/services/networking_events.jpg';
import img03 from '../../assets/img/services/literary-agent.jpg';
import img04 from '../../assets/img/services/next_bestseller.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'remixicon/fonts/remixicon.css';

const Services = () => {
  const { modalInfo, activeTab, openModal, closeModal } = useModalManager();

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <p>What we do offer</p>
        </div>

        <section id="features" className="features">
          <div className="container">
            <ul className="nav nav-tabs row d-flex">
              <li className="nav-item col-3" onClick={() => openModal('infoModal-1')}>
                <button 
                  className={getModalClassName('infoModal-1', activeTab)} 
                  style={getButtonStyle()}>
                  <i className="ri-movie-2-line"></i>
                  <h4 className="d-none d-lg-block" style={{ textAlign: 'left' }}>BOOK TO FILM REPRESENTATION</h4>
                </button>
              </li>
              <li className="nav-item col-3" onClick={() => openModal('infoModal-2')}>
                <button 
                  className={getModalClassName('infoModal-2', activeTab)} 
                  style={getButtonStyle()}>
                  <i className="ri-calendar-event-line"></i>
                  <h4 className="d-none d-lg-block" style={{ textAlign: 'left', flexGrow: 1 }}>BOOK SIGNINGS & NETWORKING EVENTS</h4>
                </button>
              </li>
              <li className="nav-item col-3" onClick={() => openModal('infoModal-3')}>
                <button 
                  className={getModalClassName('infoModal-3', activeTab)} 
                  style={getButtonStyle()}>
                  <i className="ri-briefcase-fill"></i>
                  <h4 className="d-none d-lg-block" style={{ textAlign: 'left', flexGrow: 1 }}>LITERARY AGENT SERVICES</h4>
                </button>
              </li>
              <li className="nav-item col-3" onClick={() => openModal('infoModal-4')}>
                <button 
                  className={getModalClassName('infoModal-4', activeTab)} 
                  style={getButtonStyle()}>
                  <i className="ri-store-line"></i>
                  <h4 className="d-none d-lg-block" style={{ margin: 0, textAlign: 'left', flexGrow: 1 }}>MEDIA RELATIONS & MEDIA KIT DEVELOPMENT</h4>
                </button>
              </li>
            </ul>
          </div>

          {/* Modals */}
          <Modal show={modalInfo.show && modalInfo.modalId === 'infoModal-1'} onHide={closeModal} centered>
            <Modal.Header closeButton className="custom-modal-close">
              <Modal.Title>BOOK TO FILM REPRESENTATION</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                In the world of film and movies, intrigue and controversy sells. Every video or film production has
                a purpose, but that purpose is likely to be missed without screenwriting. Screenwriting is what sets the
                foundation of all videos and films. Such is the significance of an industry-standard screenplay.
                <br /><br />
                New Leaf Media provides you with top of the line, industry standard screenplay giving emphasis on
                selling the idea of your story for Movie or TV adaptation. Our Screenwriting team is an essential part
                of your film and TV objective because they're responsible for creating the dialogue, characters, and
                storyline that make up a movie script, or screenplay.
                <br />
                New Leaf Media Screenwriting Team - requires the ability to perform the following duties:
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li><i className="ri-check-double-line"></i> Develop and research ideas for original movie screenplays.</li>
                <li><i className="ri-check-double-line"></i> Create an initial framework, or treatment, for screenplays.</li>
                <li><i className="ri-check-double-line"></i> Write or adapt a story into a script.</li>
                <li><i className="ri-check-double-line"></i> Meet with film executives to pitch screenplays and ideas.</li>
                <li><i className="ri-check-double-line"></i> Weave together visual elements in scenes with plot and dialogue.</li>
                <li><i className="ri-check-double-line"></i> Work with producers and directors to edit and adjust the script as needed.</li>
              </ul>
              <p>
                It’s nearly impossible to find anyone else in the industry that can equal our services and prices, let alone our turnaround time and quality. 
                With the reputation and connections we have built through the years compared to your mediocre script coverage company, when you purchase screenwriting 
                services through us, more of your investment is going to the decision maker, not some secretarial broker.
              </p>
              <img src={img01} alt="" className="img-fluid" />
            </Modal.Body>
          </Modal>

          <Modal show={modalInfo.show && modalInfo.modalId === 'infoModal-2'} onHide={closeModal} centered>
            <Modal.Header closeButton className="custom-modal-close">
              <Modal.Title>BOOK SIGNINGS & NETWORKING EVENTS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                The team will identify book-related events, as well as contact bookstores and other venues that would
                be willing to host book signings, book readings, and other relevant events. In addition, if the client
                is interested in participating in specific book fairs and other networking events, the appropriate
                venues will be researched, coordinated, and scheduled accordingly.
              </p>
              <img src={img02} alt="" className="img-fluid" />
            </Modal.Body>
          </Modal>

          <Modal show={modalInfo.show && modalInfo.modalId === 'infoModal-3'} onHide={closeModal} centered>
            <Modal.Header closeButton className="custom-modal-close">
              <Modal.Title>LITERARY AGENT SERVICES</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This will help the author secure a literary agent. We will conduct research matching with the author’s
                criteria and genre. The team will work with the author to develop such materials as the Query Letter,
                Synopsis, Proposal, and Sample Excerpts. A meeting will be scheduled to discuss findings and the
                team will pitch the book to the agents selected by the author. Materials will then be submitted
                to the interested contacts.
              </p>
              <img src={img03} alt="" className="img-fluid" />
            </Modal.Body>
          </Modal>

          <Modal show={modalInfo.show && modalInfo.modalId === 'infoModal-4'} onHide={closeModal} centered>
            <Modal.Header closeButton className="custom-modal-close">
              <Modal.Title>MEDIA RELATIONS & MEDIA KIT DEVELOPMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Our team of publicists will conduct comprehensive media campaigns to provide clients with local, niche
                and national media exposure consisting of television, radio, print and digital media outlets. Coverage
                would include interviews, articles, press releases, etc.
                <br /> <br />
                PR services include the following:
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li><i className="ri-check-double-line"></i> PR planning, strategy, media list construction.</li>
                <li><i className="ri-check-double-line"></i> Pitch creation, outreach, follow-up and coordination of interviews.</li>
                <li><i className="ri-check-double-line"></i> Book Reviews, as well as other specifics requested by editors.</li>
              </ul>
              <p>
                All public relations-related content, such as pitches, press releases, and a 2-page media kit, will be included.
              </p>
              <p className="fst-italic">
                Typical Process: Custom-Built Media Lists and Personalized Outreach
              </p>
              <img src={img04} alt="" className="img-fluid" />
            </Modal.Body>
          </Modal>

        </section>

        <div className="row gy-4">
          <div className="col-lg-6 col-md-6">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-card-checklist"></i></div>
              <h4 className="title"><button style={{ border: 'none', background: 'none', padding: 0 }}>Branding</button></h4>
              <p className="description">We ensure global outreach through traditional and new media channels, complemented
                by a dedicated New Leaf Campaign Manager, working closely with our PR partners to tailor your PR
                program.</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-book"></i></div>
              <h4 className="title"><button style={{ border: 'none', background: 'none', padding: 0 }}>Design</button></h4>
              <p className="description">Our service offers carefully curated design books that respect the authors' ideas
                and the unique design of each publication, providing concise insights and inspiration to fuel creativity
                and innovation in your projects.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
